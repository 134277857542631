/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h2: "h2",
    a: "a",
    div: "div",
    h3: "h3"
  }, _provideComponents(), props.components), {HrefLangManager, TableOfContents, ButtonCta, SideBySide} = _components;
  if (!ButtonCta) _missingMdxReference("ButtonCta", true);
  if (!HrefLangManager) _missingMdxReference("HrefLangManager", true);
  if (!SideBySide) _missingMdxReference("SideBySide", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  return React.createElement(React.Fragment, null, React.createElement(HrefLangManager, {
    alternates: [['x-default', 'https://www.hear.com/hearing-aids/brands/starkey/'], ['en', 'https://www.hear.com/hearing-aids/brands/starkey/'], ['en-US', 'https://www.hear.com/hearing-aids/brands/starkey/'], ['en-CA', 'https://ca.hear.com/hearing-aids/brands/starkey/']]
  }), "\n", React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "It is no secret that the ability to hear has a tremendous impact on your quality of life. So when your hearing deteriorates or you experience an impairment, it is important that you find the right hearing aids with the best technology. Starkey Hearing Technologies is one hearing aid manufacturer among many that specializes in modern and innovative devices. Starkey hearing aids are made in America and “designed for your life.” With people’s needs in mind, Starkey hearing aids are digital and wireless, and they come in different styles."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "connect-starkey-hearing-aids-with-tv-smartphone-or-computer",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#connect-starkey-hearing-aids-with-tv-smartphone-or-computer",
    "aria-label": "connect starkey hearing aids with tv smartphone or computer permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Connect Starkey hearing aids with TV, smartphone or computer"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Modern hearing aids are a valuable help for anyone who may be experiencing difficulties with their hearing. The devices’ sophisticated technology allows you to hear sounds clearly, even in demanding listening environments. In some situations, modern hearing aids can accomplish even more than just restore your ability to hear; you can use your hearing aids to connect directly and wirelessly to other electronic devices, such as a TV, landline telephone, smartphone, computer, or stereo system."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The advanced technology inside these tiny devices creates a hearing experience that sounds very natural. But how do these connectivity features work? What exactly are the benefits of hearing aids that connect to other devices? With a wireless connection, hearing aids can communicate with each other and exchange signals with the help of accessories like a streamer."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Usually the signal exchange happens via radio. The streamer can receive signals from almost any Bluetooth-enabled device (e.g. smartphone, computer, or car stereo) and relay the audio to your hearing aid system. Modern hearing aids work like a personal set of earphones, receiving the audio signals from your electronic device and delivering them directly to your ears. Starkey, and many other manufacturers, offer several products that go beyond just a streamer."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "For example, Starkey has a family of products under the SurfLink name that transform your hearing aids into a hands-free cell phone transmitter and assistive listening device. Additionally, it can work as a remote microphone and a remote control for your hearing aid system. The advanced technology of the 21st century has helped produced some of the most intelligent and intuitive hearing aids to date. And to complement this technology, hearing aids can be paired with accessories, allowing your hearing aids to fit seamlessly into your life."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "take-control-of-your-hearing-with-starkeys-smartphone-apps",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#take-control-of-your-hearing-with-starkeys-smartphone-apps",
    "aria-label": "take control of your hearing with starkeys smartphone apps permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Take control of your hearing with Starkey’s smartphone apps"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Starkey gives you added control of your hearing aids with its TruLink app and Relax app. With its newly optimized interface, the TruLink app is even easier to operate. You can individually tailor the hearing programs to your preferences. You also have the option to define a favorite location. The new Starkey Relax app is for pure relaxation. This app can play different sounds (e.g. ocean sounds, guitar music, the sound of bells) that help you relax and treat tinnitus. The soundscape is customizable and can be adapted with respect to volume, bass and treble settings. Additionally, the app features a sleep timer function that allows you to set when the app should turn itself off. All Starkey apps are free."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "starkey-soundlens-virtually-invisible",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#starkey-soundlens-virtually-invisible",
    "aria-label": "starkey soundlens virtually invisible permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Starkey SoundLens: Virtually Invisible"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "One of Starkey’s groundbreaking innovations is its invisible hearing aid, SoundLens. This is a custom-made hearing aid model with a particularly small design. Even though this hearing aid is tiny, SoundLens delivers crystal-clear sound at a comfortable listening volume. Moreover, this digital hearing aid is completely programmable and features many technical functions. For example, Starkey’s Voice iQ² presents a modern system that effectively suppresses noise. Additionally, the PureWave feature prevents audio feedback and unwanted noise. Lastly, the T² remote control allows you to operate the hearing system from a distance."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The right hearing aids, not just any hearing aids\nWhen it comes to your hearing, you shouldn’t settle for just any device that makes sounds louder. You need hearing aids that are tailored to your individual needs and preferences. You also need the right technology to treat your specific hearing loss. Not only that, but the hearing aids need to fit comfortably in or behind your ears. This may sound a little overwhelming, but with the right help, finding the right hearing aids is actually pretty easy. This is where we can help!"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "We at hear.com are staffed with highly trained hearing aid experts who can help make sense of all the available information on hearing loss solutions. Our job is to learn more about you – your lifestyle, your hearing loss, and your concerns. Based on the conversation, your personal consultant will suggest hearing aids that have the right technology and comfort level. The next step is to visit one of our partner providers in your area and have a comprehensive hearing test. The hearing specialist will use the data from your test, along with the information we provided them (your customer profile), and recommend suitable devices. Once your devices are properly fitted and adjusted, you will begin to experience better hearing, day by day."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Call today for your free consultation. Let us help you find the best hearing aid for you."), "\n", React.createElement(ButtonCta, {
    copy: "TRY STARKEY HEARING AIDS NOW",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "discover-starkey-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#discover-starkey-hearing-aids",
    "aria-label": "discover starkey hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Discover Starkey Hearing Aids"), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/starkey-halo.jpg"
  }, React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "starkey-halo",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#starkey-halo",
    "aria-label": "starkey halo permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Starkey Halo"), React.createElement(_components.p, {
    className: "c-md-p"
  }, "We rely on technology everyday by texting friends and family with our mobile phones, streaming movies on our iPads, watching movies on our computers and televisions, and much more! We listen to conversations through Bluetooth® earpieces and search for restaurants by using voice recognition with our electronic devices. Hearing aids today have advanced technology to make your life more simple."), React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.a, {
    href: "/hearing-aids/brands/starkey/halo/",
    className: "c-md-a"
  }, "Read more"))), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/starkey-muse.jpg"
  }, React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "starkey-muse",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#starkey-muse",
    "aria-label": "starkey muse permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Starkey Muse"), React.createElement(_components.p, {
    className: "c-md-p"
  }, "For those who don’t know, Starkey Hearing Technologies is one of the biggest names in the world of hearing aids and other auditory technology. Based in the USA, Starkey has been trendsetters for decades in fields like miniaturization, wireless technology, comfortable in-canal hearing aids and programmable settings – and the Starkey Muse builds on their reputation."), React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.a, {
    href: "/hearing-aids/brands/starkey/muse/",
    className: "c-md-a"
  }, "Read more"))));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
